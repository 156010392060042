import { BackendEnvironmentTypes } from '@ecocoach/domain-store-modules/src/services/api.service'

export function backendEnvironment(): BackendEnvironmentTypes {
  if (process.env.VUE_APP_ENVIRONMENT) {
    // when run locally with serve:production|preproduction|staging|develoment
    return process.env.VUE_APP_ENVIRONMENT as BackendEnvironmentTypes
  }
  if (window.location.origin.includes(BackendEnvironmentTypes.DEVELOPMENT)) {
    return BackendEnvironmentTypes.DEVELOPMENT
  }
  if (window.location.origin.includes(BackendEnvironmentTypes.STAGING)) {
    return BackendEnvironmentTypes.STAGING
  }
  return BackendEnvironmentTypes.PRODUCTION
}
